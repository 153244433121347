import cx from "classnames";
import PropTypes from "prop-types";
import { Component } from "react";

import { PLUGIN_LOGO_ICON_COMPONENTS } from "metabase/plugins";

class DefaultLogoIcon extends Component {
  static defaultProps = {
    height: 32,
  };
  static propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    dark: PropTypes.bool,
  };

  render() {
    const { dark, height, width } = this.props;
    return (
      <img style={{ marginLeft: '120px'}} src="../app/assets/img/logo.png" height={height} className={cx("Icon", { "text-brand": !dark }, { "text-white": dark })}></img>
    );
  }
}

export default function LogoIcon(props) {
  const [Component = DefaultLogoIcon] = PLUGIN_LOGO_ICON_COMPONENTS;
  return <Component {...props} />;
}
